import React from 'react';

import SEO from '../components/seo';
import Subheader from '../components/subheader';
import { Markdown } from '../containers/markdown';
import { Bright, ContainerOuter, grid12, GridHalfFull } from '../styles';
import { getLocalizationObj } from '../utilities/getLocalizationObj';

export const PrivacyTemplate = ({ data }) => {
  const { text, meta, subheader } =
    getLocalizationObj(data.strapi?.privacy) || {};
  const { title = 'Datenschutz', description } = meta || {};

  return (
    <>
      <SEO description={description} title={title} />
      <Subheader css={{ pb: [10, undefined, 20] }} intro={subheader} />
      <Bright>
        <ContainerOuter
          className={grid12}
          css={{
            bg: 'tom.bgBright',
            margin: 'auto',
            maxW: 'var(--max-width)',
            px: 'var(--px)',
            zIndex: 2,
            pos: 'relative',
            pt: 0,
          }}
        >
          <GridHalfFull
            css={{
              pb: ['', undefined, '30vh'],
              pt: [6, undefined, '12'],
              media: {
                '(orientation: portrait) and (min-width: 62rem)': {
                  pb: '10vh',
                },
              },
            }}
          >
            <Markdown reverseHeadlines>{text}</Markdown>
          </GridHalfFull>
        </ContainerOuter>
      </Bright>
    </>
  );
};
