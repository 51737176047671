import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../../containers/layout';
import { PrivacyTemplate } from '../../pageTemplates/privacy';

const Privacy = ({ data }) => {
  return (
    <Layout>
      <PrivacyTemplate data={data} />
    </Layout>
  );
};

export const query = graphql`
  query PrivacyEN {
    strapi {
      privacy {
        localizations {
          text
          subheader
          meta {
            title
            description
          }
        }
      }
    }
  }
`;

export default Privacy;
